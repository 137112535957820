<template>
  <div class="payment">
    <div class="product-info">
      <div class="title">{{orderInfo.productTitle}}</div>
      <div class="order-info cl">
        <div class="fl">
          <span>{{$t('DepartureDate')}}：{{orderInfo.priceDate}}</span>
          <span>&#x3000;{{$t('Tour group number')}}：{{orderInfo.productNo}}</span>
        </div>
        <div class="fr price">
          <span class="unit">{{$t('Total amount')}}：{{orderInfo.billingType}}</span> {{orderInfo.totalPrice}}
        </div>
      </div>
    </div>
    <!--
    <div class="countdown">
      請於15分鐘內完成網上支付（倒數：
      <countdown @finish="orderInvalid" ref="countdown"/>
      ）
    </div>
    -->
    <tabs type="border-card" v-model="paymentType">
      <tab-pane :label="$t('CashPayment')" name="CashPayment">
        <div style="padding:50px;text-align:center">
          <button class="button button-primary" @click="onCashPayment">{{this.$t('CashPayment')}}</button>
        </div>
      </tab-pane>
      <!--
  <tab-pane :label="$t('UnionPay')" name="UnionPay">
    <div class="form-item cl">
      <div class="fl label">{{$t('Credit card number')}}：</div>
      <div class="fl">
        <input class="ipt" type="text" autocomplete="off"/>
      </div>
    </div>
    <div class="form-item cl">
      <div class="fl label">{{$t('Validity Period')}}：</div>
      <div class="fl">
        <el-date-picker class="select" type="month" size="small" :picker-options="pickerOptions"
                        value-format="yyyy-MM"
                        v-model="validityPeriod"/>
      </div>
    </div>
    <div class="form-item cl">
      <div class="fl label">{{$t('Verification code')}}：</div>
      <div class="fl">
        <input class="ipt" type="text" autocomplete="off"
               :placeholder="$t('3 digit verification code on the back of the credit card')"/>
      </div>
    </div>
    <div class="form-item cl">
      <div class="fl label">{{$t('Cardholder Name')}}：</div>
      <div class="fl">
        <input class="ipt" type="text" autocomplete="off"/>
      </div>
    </div>
    <div class="form-item cl">
      <div class="fl label">{{$t('Receipt up')}}：</div>
      <div class="fl">
        <input class="ipt" type="text" autocomplete="off"
               :placeholder="$t('Electronic receipt [Payee] name (optional)')"/>
      </div>
    </div>
        <div class="actions">
          <button class="button button-primary">{{$t('Buy')}}</button>
        </div>
      </tab-pane>
      <tab-pane label="PayPal" name="paypal">
        <div class="txt-c" style="height:210px"
             v-loading="payPalOrderId==='LOADING'"
             element-loading-background="transparent">
          <div id="paypal-button" style="display:inline-block;margin-top:80px;"></div>
        </div>
      </tab-pane>
      <tab-pane label="AlipayHK" name="aliPC2Mobile">
        <div class="qrcode" v-loading="!aliPC2Mobile" element-loading-background="transparent">
          <iframe width="200" height="210" frameborder="none" scrolling="no" :src="aliPC2Mobile"
                  v-if="aliPC2Mobile"></iframe>
        </div>
      </tab-pane>
      <tab-pane label="$t('WechatPay')"  name="WeChatPay">{{$t('WeChatPay')}}</tab-pane>
      -->
    </tabs>
  </div>
</template>
<script>
  import {Tabs, TabPane} from 'element-ui';
  // import Countdown from '../../../../components/Countdown';
  import {cashPayment} from '../../../../api/admin-order';

  export default {
    name: 'AddOrderPayment',
    props: {
      orderInfo: Object
    },
    data() {
      return {
        pickerOptions: {
          disabledDate(time) {
            return time.getTime() < Date.now();
          }
        },
        timer: null,
        paymentType: 'CashPayment',
        validityPeriod: '',
        aliPC2Mobile: '',
        payPalOrderId: ''
      };
    },
    // components: {Countdown, Tabs, TabPane},
    components: {Tabs, TabPane},
    destroyed() {
      this.clearTimer();
    },
    created() {
      // this.timer = setInterval(this.getOrderPayStatus, 900);
      this.$nextTick(() => {
        this.$refs.countdown.start(15 * 60 * 1000);
      });
    },
    methods: {
      // 超时未支付弹窗
      orderInvalid() {
        this.$alert(this.$t('Payment timeout'), this.$t('Prompt'), {
          showClose: false,
          callback: action => {
            this.$router.go(-1);
          }
        });
      },
      // 清除查询订单定时器
      clearTimer() {
        if (this.timer) {
          clearInterval(this.timer);
          this.timer = null;
        }
      },
      // 支付成功
      paymentSuccessful() {
        window.sessionStorage.removeItem('orderInfo');
        this.$alert(this.$t('Payment successful'), this.$t('Prompt'), {
          customClass: 'successful',
          type: 'success',
          showClose: false,
          callback: action => {
            this.$emit('success');
          }
        });
      },
      // 现金支付
      onCashPayment() {
        cashPayment(this.orderInfo.orderId).then(res => {
          this.paymentSuccessful();
        });
      }
    }
  };
</script>
<style scoped lang="less">
  .payment{
    padding:30px 0;background-color:#fff;
    .actions{margin-top:20px;text-align:center;}
    /deep/ .el-tabs--border-card > .el-tabs__content{padding:30px 20px;}
  }
  .product-info{
    padding:20px;background-color:#f4f4f4;
    .title{font-size:18px;}
  }
  .order-info{
    margin-top:10px;
    .price{color:#ff6f61;font-size:24px;}
    .unit{font-size:16px;}
  }
  .countdown{margin:30px 0;color:#ff6f61;}
  .form-item{
    margin-bottom:20px;
    &:last-child{margin-bottom:0;}
    .label{min-width:84px;line-height:32px;}
    .ipt{
      width:300px;height:32px;padding:0 10px;border:1px solid #d9d9d9;border-radius:4px;
      &:focus{border-color:#ff6f61;}
    }
    .select{width:300px;vertical-align:top;}
    .unit{padding:0 5px;line-height:32px;}
  }
  .qrcode{position:relative;width:200px;height:210px;margin:50px auto;}
</style>
