<template>
  <div class="checkbox-wrap" @click="selected">
    <img class="ico"
         :src="value?require('../assets/images/checkbox_selected.png'):require('../assets/images/checkbox.png')"/>
    <slot></slot>
  </div>
</template>
<script>
  export default {
    name: 'Checkbox',
    props: {
      value: {
        type: Boolean,
        default: false
      },
      canClick: {
        type: Boolean,
        default: true
      }
    },
    methods: {
      selected() {
        if (this.canClick) {
          this.$emit('input', !this.value);
          this.$emit('change', !this.value);
        }
      }
    }
  };
</script>
<style scoped lang="less">
  .checkbox-wrap{
    overflow:hidden;cursor:pointer;user-select:none;
    .ico{float:left;width:16px;height:16px;}
    /deep/ .checkbox-label{float:left;margin-left:4px;line-height:16px;}
  }
</style>
