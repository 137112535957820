<template>
  <div class="passenger-list" v-if="$parent.$v">
    <add-order-passenger-info-item v-for="(item,index) of $parent.$v.passengerInfo.$each.$iter"
                                   :appellation-options="appellationOptions"
                                   :country-options="countryOptions"
                                   :area-code-options="areaCodeOptions"
                                   :idcard-type-options="idcardTypeOptions"
                                   :birthday-options="birthdayOptions"
                                   :effective-date-options="effectiveDateOptions"
                                   :passenger-list.sync="passengerList"
                                   :datas="item"
                                   :item-index="Number(index)"
                                   @update-list="updatePassengerList"
                                   :key="index"/>
  </div>
</template>
<script>
  import {getDictList} from '../../../../api/admin-common';
  import AddOrderPassengerInfoItem from './AddOrderPassengerInfoItem';

  export default {
    name: 'PassengerInfo',
    props: {
      appellationOptions: Array,
      areaCodeOptions: Array,
      idcardTypeOptions: Array,
      passengerList: Array
    },
    data() {
      return {
        countryOptions: [],
        birthdayOptions: {
          disabledDate(time) {
            return time.getTime() > Date.now();
          }
        },
        effectiveDateOptions: {
          disabledDate(time) {
            return time.getTime() < Date.now();
          }
        }
      };
    },
    components: {AddOrderPassengerInfoItem},
    created() {
      this.getDicts();
    },
    methods: {
      // 更新旅客信息列表
      updatePassengerList(passengerList) {
        this.$emit('update:passengerList', passengerList);
      },
      // 获取字典
      getDicts() {
        // 国家/证件签发地
        getDictList('country').then(res => {
          this.countryOptions = res.value || [];
        });
      }
    }
  };
</script>
