<template>
  <div class="radio-wrap">
    <!--请选择与同性团友拼房的旅客-->
    <div class="tips">{{$t('Passengers who choose to share rooms')}}</div>
    <template v-for="(item,index) in passengerInfo">
      <radio class="medium" :value="item.isFight" label="Y" :key="index"
             @click.native="changeRadio(index)"
             v-if="item.travelerType==='adult'">
        {{$t('PassengersNumber',[index+1,$t('AdultPrice')])}}
      </radio>
    </template>
  </div>
</template>
<script>
  import Radio from '../../../components/Radio';

  export default {
    name: 'JoinRoom',
    props: {passengerInfo: Array},
    components: {Radio},
    methods: {
      changeRadio(index) {
        const passengerInfo = this.passengerInfo.map((item, i) => {
          let newItem = {...item};
          newItem.isFight = i === index ? 'Y' : 'N';
          return newItem;
        });
        this.$emit('update:passengerInfo', passengerInfo);
      }
    }
  };
</script>
<style scoped lang="less">
  .radio-wrap{
    .medium{
      margin-top:10px;
      /deep/ .label{font-size:14px;}
    }
  }
</style>
