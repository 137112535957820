<template>
  <div class="total-price">
    <span class="b">{{$t('Total')}}：</span>
    <el-popover popper-class="popover-price-detail" placement="left" width="500" trigger="hover">
      <!--费用明细 start-->
      <table class="price-detail" v-if="isTicket">
        <tr>
          <td class="b">{{$t('Project')}}</td>
          <td class="b">{{$t('Price')}}</td>
          <td class="b">{{$t('Quantity')}}</td>
          <td class="b">{{$t('Amount')}}</td>
        </tr>
        <tr v-if="adultNumber">
          <td>{{$t('Adult')}}</td>
          <td>{{currentPrice.billingType}} {{currentPrice.adultPrice/100}}</td>
          <td>{{adultNumber}}</td>
          <td>{{currentPrice.billingType}} {{currentPrice.adultPrice*adultNumber/100}}</td>
        </tr>
        <tr v-if="elderlyNumber">
          <td>{{$t('Elderly')}}</td>
          <td>{{currentPrice.billingType}} {{currentPrice.elderlyPrice/100}}</td>
          <td>{{elderlyNumber}}</td>
          <td>{{currentPrice.billingType}} {{currentPrice.elderlyPrice*elderlyNumber/100}}</td>
        </tr>
        <tr v-if="childNumber">
          <td>{{$t('Child')}}</td>
          <td>{{currentPrice.billingType}} {{currentPrice.childPrice/100}}</td>
          <td>{{childNumber}}</td>
          <td>{{currentPrice.billingType}} {{currentPrice.childPrice*childNumber/100}}</td>
        </tr>
        <tr v-if="babyNumber">
          <td>{{$t('Baby')}}</td>
          <td>{{currentPrice.billingType}} {{currentPrice.babyPrice/100}}</td>
          <td>{{babyNumber}}</td>
          <td>{{currentPrice.billingType}} {{currentPrice.babyPrice*babyNumber/100}}</td>
        </tr>
      </table>
      <table class="price-detail" v-else>
        <tr>
          <td class="b">{{$t('Project')}}</td>
          <td class="b">{{$t('Price')}}</td>
          <td class="b">{{$t('Quantity')}}</td>
          <td class="b">{{$t('Amount')}}</td>
        </tr>
        <tr>
          <td>{{$t(currentPrice.priceType === '1'?'Passenger':'AdultPrice')}}</td>
          <td>{{currentPrice.billingType}} {{currentPrice.adultPrice/100}}</td>
          <td>{{adultNumber}}</td>
          <td>{{currentPrice.billingType}} {{currentPrice.adultPrice*adultNumber/100}}</td>
        </tr>
        <tr v-if="roomNumberDetail.childRoomsNumber">
          <td>{{$t('ChildPrice')}}</td>
          <td>{{currentPrice.billingType}} {{currentPrice.childPrice/100}}</td>
          <td>{{roomNumberDetail.childRoomsNumber}}</td>
          <td>{{currentPrice.billingType}} {{currentPrice.childPrice*roomNumberDetail.childRoomsNumber/100}}</td>
        </tr>
        <tr v-if="roomNumberDetail.notChildRoomsNumber">
          <td>{{$t('NotChildPrice')}}</td>
          <td>{{currentPrice.billingType}} {{currentPrice.notChildPrice/100}}</td>
          <td>{{roomNumberDetail.notChildRoomsNumber}}</td>
          <td>{{currentPrice.billingType}} {{currentPrice.notChildPrice*roomNumberDetail.notChildRoomsNumber/100}}</td>
        </tr>
        <tr v-if="elderlyNumber">
          <td>{{$t('BabyPrice')}}</td>
          <td>{{currentPrice.billingType}} {{currentPrice.babyPrice/100}}</td>
          <td>{{babyNumber}}</td>
          <td>{{currentPrice.billingType}} {{currentPrice.babyPrice*babyNumber/100}}</td>
        </tr>
        <tr v-if="babyNumber">
          <td>{{$t('BabyPrice')}}</td>
          <td>{{currentPrice.billingType}} {{currentPrice.babyPrice/100}}</td>
          <td>{{babyNumber}}</td>
          <td>{{currentPrice.billingType}} {{currentPrice.babyPrice*babyNumber/100}}</td>
        </tr>
        <tr v-if="roomNumberDetail.annexNumber">
          <td>{{$t('SingleBedDifference')}}</td>
          <td>{{currentPrice.billingType}} {{currentPrice.singleBedPrice/100}}</td>
          <td>{{roomNumberDetail.annexNumber}}</td>
          <td>{{currentPrice.billingType}} {{currentPrice.singleBedPrice*roomNumberDetail.annexNumber/100}}</td>
        </tr>
        <template v-if="isInsurance==='Y'&&currentPrice.insurance">
          <tr>
            <td>
              {{currentPrice.insurance.title}}{{currentPrice.priceType!=='1'?`-${$t('Adult')}`:''}}
              (<span class="days">{{currentPrice.days||currentPrice.lates}}{{$t('Day')}}</span>)
            </td>
            <td>{{currentPrice.billingType}} {{currentPrice.insurance.adultPrice}}</td>
            <td>{{adultNumber}}</td>
            <td>
              {{currentPrice.billingType}} {{insurancePrice.adult/100}}
            </td>
          </tr>
          <tr v-if="currentPrice.priceType!=='1'&&(childNumber+babyNumber)">
            <td>
              {{currentPrice.insurance.title}}-{{$t('Child')}}/{{$t('Baby')}}
              (<span class="days">{{currentPrice.days||currentPrice.lates}}{{$t('Day')}}</span>)
            </td>
            <td>{{currentPrice.billingType}} {{currentPrice.insurance.childPrice}}</td>
            <td>{{childNumber+babyNumber}}</td>
            <td>{{currentPrice.billingType}} {{insurancePrice.child/100}}</td>
          </tr>
        </template>
      </table>
      <!--费用明细 end-->
      <span class="value" slot="reference">
        <span class="currency-symbol">{{currentPrice.billingType}}</span>{{totalPrice}}
      </span>
    </el-popover>
  </div>
</template>
<script>
  export default {
    name: 'ChargeDetailsPopover',
    props: {
      currentPrice: Object,
      roomNumberDetail: Object,
      adultNumber: [Number, String],
      elderlyNumber: [Number, String],
      childNumber: [Number, String],
      babyNumber: [Number, String],
      totalPrice: [Number, String],
      isInsurance: Boolean,
      isTicket: String
    }
  };
</script>
<style scoped lang="less">
  .total-price{
    margin-bottom:15px;padding-top:10px;
    .value{border-bottom:1px dotted #ff6f61;color:#ff6f61;font-size:36px;cursor:pointer;}
    .currency-symbol{font-size:16px;}
  }
  .price-detail{
    width:100%;
    td{padding:8px;}
    .days{color:#ff6f61;}
  }
</style>
<style lang="less">
  .el-popper.popover-price-detail{
    border-color:#ffd4d0;
    &[x-placement^=left] .popper__arrow{border-left-color:#ffd4d0;}
    /*&[x-placement^=bottom] .popper__arrow{border-bottom-color:#ffd4d0;}
    &[x-placement^=top] .popper__arrow{border-top-color:#ffd4d0;}
    .line-item-info{
      margin-top:5px;white-space:nowrap;
      &:first-child{margin-top:0;}
    }*/
  }
</style>
