<template>
  <div class="main">
    <breadcrumb>
      <breadcrumb-item>{{$t('OrderManagement')}}</breadcrumb-item>
      <breadcrumb-item>{{$t('NewManualOrder')}}</breadcrumb-item>
    </breadcrumb>
    <template v-if="step===0">
      <div class="filter cl">
        <!--產品編號-->
        <div class="item cl">
          <div class="label">{{$t('ProductNumber')}}：</div>
          <div class="fl">
            <el-select class="select"
                       :placeholder="$t('PleaseEnter')"
                       v-model="productId"
                       size="small"
                       filterable
                       remote
                       reserve-keyword
                       :remote-method="searchProductsForNumber"
                       :loading="loadingList">
              <el-option v-for="(item,index) of products"
                         :key="index||item.productId"
                         :label="item.productCode"
                         :value="item.productId">
                <span class="fl">{{item.productCode}}</span>
                <span class="fr">{{item.title}}</span>
              </el-option>
            </el-select>
          </div>
        </div>
        <!--產品名稱-->
        <div class="item cl">
          <div class="label">{{$t('ProductName')}}：</div>
          <div class="fl">
            <el-select class="select"
                       :placeholder="$t('PleaseEnter')"
                       v-model="productId"
                       size="small"
                       filterable
                       remote
                       reserve-keyword
                       :remote-method="searchProductsForName"
                       :loading="loadingList">
              <el-option v-for="(item,index) of products"
                         :key="index||item.productId"
                         :label="item.title"
                         :value="item.productId">
                <span class="fl">{{item.title}}</span>
                <span class="fr">{{item.productCode}}</span>
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="fl">
          <!--搜索-->
          <!--<button class="button button-primary small m-r" type="button">{{$t('search')}}</button>-->
          <!--清除-->
          <button class="button small" type="button" @click="clearData">{{$t('Clear')}}</button>
        </div>
      </div>
      <template v-if="productId">
        <div class="fl-group">
          <div class="bd product-info">
            <div class="cl">
              <!--產品編號-->
              <p class="fl">{{$t('ProductNumber')}}：{{product.productNo}}</p>
              <!--產品名稱-->
              <p class="fl">{{$t('ProductName')}}：{{product.productTitle}}</p>
            </div>
            <!--出發日期-->
            <div class="form-item">
              <div class="label">{{$t('DepartureDate')}}</div>
              <div>
                <el-date-picker class="select" type="date" size="small"
                                :picker-options="pickerOptions"
                                value-format="yyyy-MM-dd"
                                :clearable="false"
                                :placeholder="$t('PleaseChoose')"
                                v-model="departureDate">
                </el-date-picker>
              </div>
            </div>
            <!--路線-->
            <div class="form-item">
              <div class="label">{{$t('Route')}}</div>
              <div>
                <el-select class="select" v-model="lineId" size="small" :disabled="!departureDate"
                           :placeholder="$t('PleaseChoose')">
                  <el-option v-for="item in lines"
                             :key="item.lineId"
                             :label="item.lineTitle"
                             :value="item.lineId"
                             :disabled="item.lastPeopleNumber<=0">
                    <span style="float:left">{{item.lineTitle}}</span>
                    <span style="float:right;color:#c0c4cc;font-size:12px">{{item.memo}}</span>
                  </el-option>
                </el-select>
              </div>
            </div>
            <div class="cl">
              <!--成人-->
              <div class="fl form-item m-r">
                <div class="label">{{$t(currentPrice.priceType!=='1'?'Adult':'Passenger')}}</div>
                <div>
                  <el-select class="select w1" size="small" :placeholder="$t('PleaseChoose')"
                             :disabled="currentPrice.isAdult!=='Y'"
                             v-model="adultNumber">
                    <el-option v-for="i in 100"
                               :key="i"
                               :label="i"
                               :value="i"
                               :disabled="i<minAdultNumber">
                    </el-option>
                  </el-select>
                </div>
              </div>
              <template v-if="currentPrice.priceType!=='1'">
                <!--兒童(2-11歲)-->
                <div class="fl form-item m-r">
                  <div class="label">{{$t('Child')}}(2-11{{$t('YearOld')}})</div>
                  <div>
                    <el-select class="select w1" size="small" :placeholder="$t('PleaseChoose')"
                               :disabled="currentPrice.isChild!=='Y'"
                               v-model="childNumber">
                      <el-option v-for="item of childOptions"
                                 :key="item.value"
                                 :label="item.label"
                                 :value="item.value">
                      </el-option>
                    </el-select>
                  </div>
                </div>
                <!--嬰兒(0-1歲)-->
                <div class="fl form-item m-r">
                  <div class="label">{{$t('Baby')}}(0-1{{$t('YearOld')}})</div>
                  <div>
                    <el-select class="select w1" size="small" :placeholder="$t('PleaseChoose')"
                               :disabled="currentPrice.isBaby!=='Y'"
                               v-model="babyNumber">
                      <el-option v-for="item of babyOptions"
                                 :key="item.value"
                                 :label="item.label"
                                 :value="item.value">
                      </el-option>
                    </el-select>
                  </div>
                </div>
              </template>
              <!--房間數目-->
              <div class="fl form-item">
                <div class="label">{{$t('NumberOfRooms')}}</div>
                <div>
                  <el-select class="select w1" size="small" :placeholder="$t('PleaseChoose')"
                             :disabled="currentPrice.isSingleBed!=='Y'"
                             v-model="roomsNumber">
                    <el-option v-for="item of roomsOptions"
                               :key="item.value"
                               :label="item.label"
                               :value="item.value">
                    </el-option>
                  </el-select>
                </div>
              </div>
            </div>
            <!--保險安排-->
<!--            <div class="form-item" v-if="currentPrice.insurance">-->
<!--              <div class="label">{{$t('Insurance arrangement')}}</div>-->
<!--              <div class="cl">-->
<!--                <div class="fl">-->
<!--                  <radio v-model="isInsurance" label="Y">-->
<!--                    <i18n path="Purchase insurance">-->
<!--                      <a class="link" target="_blank" :href="currentPrice.insurance.fileUrl">-->
<!--                        {{$t('Platform Select Insurance')}}-->
<!--                      </a>-->
<!--                    </i18n>-->
<!--                  </radio>-->
<!--                  <div class="insurance-price">-->
<!--                    <div class="item">-->
<!--                      {{$t(currentPrice.priceType!=='1'?'Adult':'Per person')}}：-->
<!--                      {{currentPrice.billingType}} {{currentPrice.insurance.adultPrice}}-->
<!--                    </div>-->
<!--                    <div class="item" v-if="currentPrice.priceType!=='1'">-->
<!--                      {{$t('Child')}}/{{$t('Baby')}}：{{currentPrice.billingType}} {{currentPrice.insurance.childPrice}}-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </div>-->
<!--                <div class="fl">-->
<!--                  <radio v-model="isInsurance" label="N">{{$t('Self-arranged insurance')}}</radio>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
            <!--總金額-->
            <charge-details-popover :current-price="currentPrice"
                                    :room-number-detail="roomNumberDetail"
                                    :adult-number="adultNumber"
                                    :child-number="childNumber"
                                    :baby-number="babyNumber"
                                    :total-price="totalPrice"
                                    :is-insurance="isInsurance"/>
          </div>
        </div>
        <div class="actions">
          <!--填寫旅客信息-->
          <button class="button button-primary" type="button" @click="addManualOrder">
            {{$t('Fill in passenger information')}}
          </button>
        </div>
      </template>
    </template>
    <template v-else-if="step===1">
      <!--旅客資料-->
      <div class="fl-group">
        <div class="hd">{{$t('Traveler information')}}</div>
        <add-order-passenger-info :appellation-options="appellationOptions"
                                  :area-code-options="areaCodeOptions"
                                  :idcard-type-options="certificateTypeOptions"
                                  :passenger-list.sync="passengerInfo"
                                  ref="passengerInfo"/>
      </div>
      <!--拼房旅客-->
      <div class="fl-group" v-if="orderInfo.roomsNumber%1===0.5&&orderInfo.adultNumber>1">
        <div class="hd">{{$t('FightPassenger')}}</div>
        <div class="bd">
          <join-room :passenger-info.sync="passengerInfo"/>
        </div>
      </div>
      <!--聯絡人資料-->
      <div class="fl-group">
        <div class="hd">{{$t('ContactInformation')}}</div>
        <div class="bd">
          <add-order-contact-information :area-code-options="areaCodeOptions"
                                         :appellation-options="appellationOptions"
                                         :datas.sync="contactInformation"
                                         :emergency-contact.sync="emergencyContact"/>
        </div>
      </div>
      <!--收據-->
      <div class="fl-group">
        <div class="hd">{{$t('Receipt')}}</div>
        <div class="bd">
          <!--收據抬頭-->
          <div class="form-item">
            <div class="label">{{$t('Receipt up')}}({{$t('Optional')}})</div>
            <div>
              <input class="ipt m-r" type="text" autocomplete="off"
                     :placeholder="$t('Electronic receipt [Payee] name (optional)')"
                     v-model.trim="gainGround"/>
            </div>
          </div>
        </div>
      </div>
      <!--其他需求-->
      <div class="fl-group">
        <div class="hd">{{$t('More demand')}}</div>
        <div class="bd">
          <!--其他需求-->
          <div class="form-item">
            <div class="label">{{$t('Other demands')}}({{$t('Optional')}})</div>
            <div>
              <el-input type="textarea"
                        :placeholder="$t('PleaseEnter')"
                        show-word-limit
                        maxlength="1000"
                        v-model="otherDemands">
              </el-input>
              <p class="tips">{{$t('Tips')[0]}}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="actions">
        <!--上一步-->
        <button class="button m-r" type="button" @click="setStep(0)">{{$t('PreviousStep')}}</button>
        <!--確定人工訂單-->
        <button class="button button-primary" type="button" @click="checkManualOrders">
          {{$t('Determining manual orders')}}
        </button>
      </div>
    </template>
    <template v-else-if="step===2">
      <!--产品资料-->
      <div class="fl-group">
        <div class="hd">{{$t('Product information')}}</div>
        <div class="bd">
          <div class="row cl">
            <div class="fl">{{$t('DepartureDate')}}：{{orderInfo.priceDate}}</div>
            <div class="fl">{{$t('Tour group number')}}：{{orderInfo.productNo}}</div>
          </div>
          <template v-if="flightList&&flightList.length">
            <div class="h3">{{$t('FlightInformation')}}</div>
            <div class="flight-list" v-for="item of flightList" :key="item.flightId">
              <template v-if="item.isCustomize==='N'">
                <div class="item" v-for="fItem of item.flightDetails" :key="fItem.outsetDate">
                  <span>{{fItem.departure}}</span>
                  <span class="svg-icon-wrap">
                      <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" width="18" height="18"
                           viewBox="0 0 24 24">
                        <path d="M22 12l-4-4v3H3v2h15v3z"></path>
                      </svg>
                    </span>
                  <span class="spacing">{{fItem.destination}}</span>
                  <span class="spacing">{{fItem.departureDate|flightDate(fItem.fewDay)}}</span>
                  <span class="spacing">{{fItem.airline}} {{fItem.airlineNumber}}</span>
                  <span>{{fItem.outsetTime}}</span>
                  <span class="svg-icon-wrap">
                      <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" width="18" height="18"
                           viewBox="0 0 24 24">
                        <path d="M22 12l-4-4v3H3v2h15v3z"></path>
                      </svg>
                    </span>
                  <span>{{fItem.arrivalsTime}}</span>
                </div>
              </template>
              <div class="rich-text" v-html="item.customizeContent" v-else-if="item.isCustomize==='Y'"></div>
            </div>
          </template>
        </div>
      </div>
      <!--旅客資料-->
      <div class="fl-group">
        <div class="hd">{{$t('Traveler information')}}</div>
        <div class="bd">
          <table class="table">
            <tr>
              <td class="b">{{$t('Passenger Name')}}({{$t('Last Name')}}/{{$t('Ming')}})</td>
              <td class="b">{{$t('Appellation')}}</td>
              <td class="b">{{$t('Category')}}</td>
              <td class="b">{{$t('Certificate Type')}}</td>
              <td class="b">{{$t('Certificate Number')}}</td>
            </tr>
            <tr v-for="(item,index) of passengerInfo" :key="index">
              <td>{{item.englishSurname}}/{{item.englishName}}</td>
              <td>{{item.isCalled|dictName(appellationOptions)}}</td>
              <td>{{(item.travelerType+item.isOccupyBed)|dictName('travelerType')}}</td>
              <td>{{item.paperworkType|dictName(certificateTypeOptions)}}</td>
              <td>{{item.idNumber}}</td>
            </tr>
          </table>
        </div>
      </div>
      <!--聯絡人資料-->
      <div class="fl-group">
        <div class="hd">{{$t('ContactInformation')}}</div>
        <div class="bd">
          <table class="table">
            <tr>
              <td class="b">{{$t('Contact Name')}}({{$t('Last Name')}}/{{$t('Ming')}})</td>
              <td class="b">{{$t('Appellation')}}</td>
              <td class="b">{{$t('Contact Number')}}</td>
              <td class="b">{{$t('Email')}}</td>
            </tr>
            <tr>
              <td>{{contactInformation.englishSurname}}/{{contactInformation.englishName}}</td>
              <td>{{contactInformation.isCalled|dictName(appellationOptions)}}</td>
              <td>({{contactInformation.phoneType}}) {{contactInformation.userPhone}}</td>
              <td>{{contactInformation.userEmail}}</td>
            </tr>
          </table>
        </div>
      </div>
      <!--紧急联系人-->
      <div class="fl-group" v-if="emergencyContact.isUrgent">
        <div class="hd">{{$t('Emergency Contact')[0]}}</div>
        <div class="bd">
          <table class="table">
            <tr>
              <td class="b">{{$t('Contact Name')}}({{$t('Last Name')}}/{{$t('Ming')}})</td>
              <td class="b">{{$t('Contact Number')}}</td>
            </tr>
            <tr>
              <td>{{emergencyContact.englishSurname}}/{{emergencyContact.englishName}}</td>
              <td>({{emergencyContact.phoneType}}) {{emergencyContact.userPhone}}</td>
            </tr>
          </table>
        </div>
      </div>
      <!--收據-->
      <div class="fl-group" >
        <div class="hd">{{$t('Receipt')}}</div>
        <div class="bd">
          <!--收據抬頭-->
          <div class="form-item">
            <div class="label">{{$t('Receipt up')}}：{{gainGround}}</div>
          </div>
        </div>
      </div><br>
      <!--其他需求-->
      <div class="fl-group" >
        <div class="hd">{{$t('More demand')}}</div>
        <div class="bd">
          <!--其他需求-->
          <div class="form-item">
            <div class="label">{{$t('Other demands')}}：{{ otherDemands }}</div>
            <div ></div>
          </div>
        </div>
      </div><br>
      <!--詳細收費-->
      <div class="fl-group">
        <div class="hd">{{$t('Detailed Charge')}}</div>
        <div class="bd">
          <table class="table">
            <tr>
              <td class="b">{{$t('Project')}}</td>
              <td class="b">{{$t('Price')}}</td>
              <td class="b">{{$t('Quantity')}}</td>
              <td class="b">{{$t('Amount')}}</td>
            </tr>
            <tr>
              <td>{{$t(orderInfo.priceType!=='1'?'AdultPrice':'Passenger')}}</td>
              <td>{{orderInfo.billingType}} {{orderInfo.adultPrice*100/100}}</td>
              <td>{{orderInfo.adultNumber}}</td>
              <td>{{orderInfo.billingType}} {{orderInfo.adultPrice*orderInfo.adultNumber*100/100}}</td>
            </tr>
            <tr v-if="orderInfo.childNumber">
              <td>{{$t('ChildPrice')}}</td>
              <td>{{orderInfo.billingType}} {{orderInfo.childPrice*100/100}}</td>
              <td>{{orderInfo.childNumber}}</td>
              <td>{{orderInfo.billingType}} {{orderInfo.childNumber*orderInfo.childPrice*100/100}}</td>
            </tr>
            <tr v-if="orderInfo.notChildNumber">
              <td>{{$t('NotChildPrice')}}</td>
              <td>{{orderInfo.billingType}} {{orderInfo.notChildPrice*100/100}}</td>
              <td>{{orderInfo.notChildNumber}}</td>
              <td>{{orderInfo.billingType}} {{orderInfo.notChildNumber*orderInfo.notChildPrice*100/100}}</td>
            </tr>
            <tr v-if="orderInfo.babyNumber">
              <td>{{$t('BabyPrice')}}</td>
              <td>{{orderInfo.billingType}} {{orderInfo.babyPrice*100/100}}</td>
              <td>{{orderInfo.babyNumber}}</td>
              <td>{{orderInfo.billingType}} {{orderInfo.babyNumber*orderInfo.babyPrice*100/100}}</td>
            </tr>
            <tr v-if="orderInfo.singleBedNumber">
              <td>{{$t('SingleBedDifference')}}</td>
              <td>{{orderInfo.billingType}} {{orderInfo.singleBedPrice*100/100}}</td>
              <td>{{orderInfo.singleBedNumber}}</td>
              <td>{{orderInfo.billingType}} {{orderInfo.singleBedNumber*orderInfo.singleBedPrice*100/100}}</td>
            </tr>
            <template v-if="orderInfo.isInsurance==='Y'">
              <tr>
                <td>{{$t('Insurance')}}{{orderInfo.priceType!=='1'?`-${$t('Adult')}`:''}}</td>
                <td>{{orderInfo.billingType}} {{orderInfo.insuranceAdultPrice/100}}</td>
                <td>{{orderInfo.adultNumber}}</td>
                <td>{{orderInfo.billingType}} {{orderInfo.insuranceAdultTotalPrice}}</td>
              </tr>
              <tr
                v-if="orderInfo.priceType!=='1'&&(orderInfo.childNumber+orderInfo.notChildNumber+orderInfo.babyNumber)">
                <td>{{$t('Insurance')}}-{{$t('Child')}}/{{$t('Baby')}}</td>
                <td>{{orderInfo.billingType}} {{orderInfo.insuranceChildPrice*100/100}}</td>
                <td>{{orderInfo.childNumber+orderInfo.notChildNumber+orderInfo.babyNumber}}</td>
                <td>{{orderInfo.billingType}} {{orderInfo.insuranceChildTotalPrice}}</td>
              </tr>
            </template>
          </table>
        </div>
      </div>
      <div class="actions txt-c">
        <button class="button m-r" @click="setStep(1)">{{$t('PreviousStep')}}</button>
        <button class="button button-primary" @click="saveManualOrderInfo">
          {{$t('Payment')}}
        </button>
      </div>
    </template>
    <add-order-payment :order-info="orderInfo" @success="onSuccess" v-else-if="step===3"/>
  </div>
</template>
<script>
  import Vue from 'vue';
  import Vuelidate from 'vuelidate';
  import {required, requiredIf, email} from 'vuelidate/lib/validators';
  import Breadcrumb from '../../../components/admin/Breadcrumb';
  import BreadcrumbItem from '../../../components/BreadcrumbItem';
  // import Radio from '../../../components/Radio';
  import ChargeDetailsPopover from '../../../components/ChargeDetailsPopover';
  import JoinRoom from '../../order/components/JoinRoom';
  import AddOrderPassengerInfo from './components/AddOrderPassengerInfo';
  import AddOrderContactInformation from './components/AddOrderContactInformation';
  import AddOrderPayment from './components/AddOrderPayment';
  import numberOptions from '../../../mixins/numberOptions';
  import {getDictList} from '../../../api/admin-common';
  import {scrollToY, currencySymbol, getCurrency} from '../../../utils';
  import {getProductInfoList, getProductPriceListByproductId, getProductPriceByDate} from '../../../api/admin-product';
  import {addManualOrder, saveOrderMaterial} from '../../../api/admin-order';

  Vue.use(Vuelidate);
  export default {
    name: 'AdminAddOrder',
    mixins: [numberOptions],
    data() {
      return {
        step: 0,
        orderId: '',
        loadingList: false,
        areaCodeOptions: [],
        appellationOptions: [],
        certificateTypeOptions: [],
        passengerInfo: [],
        contactInformation: {
          userType: 'ordinary',
          isCalled: '',
          englishSurname: '',
          englishName: '',
          chineseSurname: '',
          chineseName: '',
          phoneType: '',
          userPhone: '',
          userEmail: ''
        },
        emergencyContact: {
          isUrgent: false,
          userType: 'urgent',
          englishSurname: '',
          englishName: '',
          chineseSurname: '',
          chineseName: '',
          phoneType: '',
          userPhone: ''
        },
        productId: '',
        products: [],
        product: {
          productNo: '',
          productTitle: ''
        },
        prices: [],
        lineId: '',
        departureDate: '',
        currentPrice: {},
        isInsurance: '',
        gainGround: '',
        otherDemands: '',
        orderInfo: {},
        flightList: null
      };
    },
    computed: {
      // 当前日期的路线
      lines: function () {
        const lines = (this.prices.find(item => item.priceDate === this.departureDate) || {}).priceDateStatuses || [];
        this.setDefaultLineId(lines);
        return lines;
      },
      priceDates: function () {
        return this.prices.map(item => {
          if (item.isTopUp === 'N') {
            return new Date(item.priceDate.replace(/-/g, '/')).getTime();
          } else {
            return '';
          }
        });
      },
      pickerOptions: function () {
        return {
          disabledDate: (time) => {
            const timestamp = time.getTime();
            return !this.priceDates.includes(timestamp);
          }
        };
      }
    },
    validations: {
      passengerInfo: {
        required,
        $each: {
          isCalled: {required},
          englishSurname: {required},
          englishName: {required},
          chineseSurname: {},
          chineseName: {},
          genderType: {required},
          paperworkType: {required},
          otherPaperwork: {
            required: requiredIf((datas) => {
              return datas.paperworkType === 'qt';
            })
          },
          issuingCountry: {required},
          idNumber: {required},
          effectiveDate: {
            required: requiredIf((datas) => {
              return datas.paperworkType !== 'sfz';
            })
          },
          birthday: {required},
          phoneType: {},
          userPhone: {},
          travelerType: {}
        }
      },
      contactInformation: {
        isCalled: {required},
        englishSurname: {required},
        englishName: {required},
        phoneType: {required},
        userPhone: {required},
        userEmail: {required, email}
      },
      emergencyContact: {
        englishSurname: {
          required: requiredIf((datas) => {
            return datas.isUrgent;
          })
        },
        englishName: {
          required: requiredIf((datas) => {
            return datas.isUrgent;
          })
        },
        phoneType: {
          required: requiredIf((datas) => {
            return datas.isUrgent;
          })
        },
        userPhone: {
          required: requiredIf((datas) => {
            return datas.isUrgent;
          })
        }
      }
    },
    components: {
      AddOrderPayment,
      JoinRoom,
      ChargeDetailsPopover,
      // Radio,
      AddOrderContactInformation,
      AddOrderPassengerInfo,
      Breadcrumb,
      BreadcrumbItem
    },
    beforeRouteEnter(to, from, next) {
      if (!to.query.orderId) {
        window.sessionStorage.removeItem('orderInfo');
      }
      next();
    },
    created() {
      const orderInfo = window.sessionStorage.getItem('orderInfo');
      if (orderInfo) {
        this.orderInfo = JSON.parse(orderInfo);
        this.orderId = this.orderInfo.orderId;
        this.createPassengers();
        this.step = 1;
      }
      this.getDicts();
    },
    methods: {
      getDicts() {
        // 手机区号
        getDictList('phonePrefix').then(res => {
          this.areaCodeOptions = res.value || [];
        });

        // 获取称谓选项
        getDictList('appellative').then(res => {
          this.appellationOptions = res.value || [];
        });

        // 证件类型
        getDictList('certificateType').then(res => {
          this.certificateTypeOptions = res.value || [];
        });
      },
      setDefaultLineId(lines) {
        if (lines.length) {
          if (!lines.find(item => item.lineId === this.lineId && item.lastPeopleNumber > 0)) {
            this.lineId = (lines.find(item => item.lastPeopleNumber > 0) || {}).lineId || '';
          }
        } else {
          this.lineId = '';
        }
      },
      // 生成旅客信息表
      createPassengers() {
        const {adultNumber, childNumber, notChildNumber, babyNumber, roomsNumber} = this.orderInfo;
        if (!this.passengerInfo.length) {
          this.step = 0; // 如果未填写旅客信息，要先填写旅客信息
          this.passengerInfo = [
            ...this.createPassengerList(adultNumber, 'adult', 'Y'),
            ...this.createPassengerList(childNumber, 'child', 'Y'),
            ...this.createPassengerList(notChildNumber, 'child', 'N'),
            ...this.createPassengerList(babyNumber, 'baby', 'N')
          ];

          // 如果客戶訂購旅團時選擇房間數目有0.5时，第一位旅客默认拼房
          if (roomsNumber % 1 === 0.5) {
            this.passengerInfo[0].isFight = 'Y';
          }
        } else {
          this.step = 2;
        }
        this.loading = false;
      },
      createPassengerList(number, travelerType, isOccupyBed) {
        let list = [];
        for (let i = 0; i < number; i++) {
          list.push({
            isCalled: '',
            englishSurname: '',
            englishName: '',
            chineseSurname: '',
            chineseName: '',
            genderType: '',
            paperworkType: '',
            otherPaperwork: '',
            issuingCountry: '',
            idNumber: '',
            effectiveDate: '',
            birthday: '',
            phoneType: '',
            userPhone: '',
            travelerType,
            isOccupyBed,
            isFight: 'N'
          });
        }
        return list;
      },
      // 模糊查询
      searchProductsForNumber(query) {
        if (query) {
          this.searchProducts({productCode: query, productTitle: ''});
        } else {
          this.products = [];
        }
      },
      searchProductsForName(query) {
        if (query) {
          this.searchProducts({productCode: '', productTitle: query});
        } else {
          this.products = [];
        }
      },
      searchProducts(data) {
        this.loadingList = true;
        getProductInfoList(data).then(res => {
          this.products = res.value || [];
          this.loadingList = false;
        });
      },
      // 获取产品价格列表
      getProductPriceList() {
        if (!this.productId) {
          return false;
        }
        getProductPriceListByproductId(this.productId).then(res => {
          const now = new Date().getTime();
          this.prices = res.value || [];
          this.departureDate = '';
          for (let item of this.prices) {
            let timestamp = new Date(item.priceDate.replace(/-/g, '/')).getTime();
            if (timestamp > now) {
              this.departureDate = item.priceDate;
              break;
            }
          }
        });
      },
      // 按日期及路线获取价格详情
      getProductPrice() {
        if (this.lineId && this.departureDate) {
          getProductPriceByDate(this.lineId, this.departureDate).then(res => {
            let currentPrice = res.value || {};
            currentPrice.billingType = currencySymbol(currentPrice.billingType);
            currentPrice.adultPrice = (currentPrice.adultPrice || 0) * 100;
            currentPrice.childPrice = (currentPrice.childPrice || 0) * 100;
            currentPrice.notChildPrice = (currentPrice.notChildPrice || 0) * 100;
            currentPrice.babyPrice = (currentPrice.babyPrice || 0) * 100;
            currentPrice.singleBedPrice = (currentPrice.singleBedPrice || 0) * 100;
            this.currentPrice = currentPrice;
            if (currentPrice.priceType === '1') {
              this.childNumber = 0;
              this.babyNumber = 0;
            }
          });
        } else {
          this.currentPrice = {};
          this.childNumber = 0;
          this.babyNumber = 0;
        }
      },
      // 清除数据
      clearData() {
        this.productId = '';
        this.passengerInfo = [];
        this.isInsurance = '';
        this.gainGround = '';
        this.otherDemands = '';
        Object.assign(this.contactInformation, this.$options.data.call(this).contactInformation);
        Object.assign(this.emergencyContact, this.$options.data.call(this).emergencyContact);
        this.$v.$reset();
      },
      // 下一步/上一步
      setStep(value) {
        this.step = value;
        this.$nextTick(() => {
          scrollToY();
        });
      },
      // 新建人工订单
      addManualOrder() {
        const data = {
          adultNumber: this.adultNumber, // 成人数
          childNumber: this.childNumber, // 小童数
          babyNumber: this.babyNumber, // 婴儿数
          roomsNumber: this.roomsNumber, // 房间数量
          productId: this.productId,
          lineId: this.lineId,
          priceDate: this.departureDate,
          totalMoney: this.totalPrice,
          languageType: this.$i18n.locale,
          isInsurance: this.isInsurance || 'N', // 是否保险
          currencyCode: getCurrency()
        };
        const loading = this.$loading({text: 'Loading'});

        addManualOrder(data).then(res => {
          loading.close();
          this.orderInfo = res.value;
          this.orderId = this.orderInfo.orderId;
          this.createPassengers();
          this.setStep(1);
          this.$router.replace({name: 'AdminAddOrder', query: {orderId: this.orderId}});
          window.sessionStorage.setItem('orderInfo', JSON.stringify(this.orderInfo));
        }).catch(() => {
          loading.close();
        });
      },
      // 確定人工訂單
      checkManualOrders() {
        this.$v.$touch();
        if (this.$v.$invalid) {
          this.$message.warning(this.$t('Please complete the information before submitting'));
          this.$nextTick(() => {
            if (document.querySelector('.is-error')) {
              scrollToY(undefined, document.querySelector('.is-error').offsetTop - 80);
            }
          });
          return false;
        }
        this.setStep(2);
      },
      // 保存人工订单的旅客等信息
      saveManualOrderInfo() {
        let datas = {
          travelers: this.passengerInfo,
          contacts: [this.contactInformation],
          memo: {
            orderId: this.orderId,
            gainGround: this.gainGround,
            otherDemands: this.otherDemands
          }
        };

        if (this.emergencyContact.isUrgent) {
          datas.contacts.push(this.emergencyContact);
        }

        const loading = this.$loading({text: 'Loading'});
        saveOrderMaterial(this.orderId, datas).then(res => {
          loading.close();
          this.setStep(3);
        }).catch(() => {
          loading.close();
        });
      },
      // 新建成功
      onSuccess() {
        this.clearData();
        this.setStep(0);
        this.$router.replace({name: 'AdminAddOrder'});
      }
    },
    watch: {
      productId(newValue, oldValue) {
        var p = this.products.find(item => item.productId === newValue);
        this.product.productNo = p.productCode;
        this.product.productTitle = p.title;
        this.getProductPriceList();
      },
      lineId: function (value) {
        this.getProductPrice();
      },
      departureDate: function () {
        this.getProductPrice();
      }
    }
  };
</script>
<style scoped lang="less">
  .main{
    .form-item{
      margin-bottom:24px;
      &:last-child{margin-bottom:0;}
      .label{margin-bottom:8px;}
      .ipt{
        width:260px;height:32px;padding:0 10px;border:1px solid #d9d9d9;border-radius:4px;
        &:focus{border-color:#ff6f61;}
      }
      .select{
        width:260px;vertical-align:top;
        &.w1{width:120px;margin-right:8px;}
      }
      .link{text-decoration:underline;color:#ff6f61;}
      .tips{margin-top:4px;color:#666;font-size:12px;}
    }
    .actions,
    /deep/ .actions{margin-top:40px;text-align:center;}
    /deep/ .radio .label{color:#321908;}
    /deep/ .el-textarea .el-textarea__inner{height:96px;padding:10px;}
  }
  .filter{
    margin-top:30px;
    .item{
      float:left;width:33.3333%;margin-bottom:24px;vertical-align:top;
      .label{float:left;line-height:32px;}
    }
    .ipt{
      width:180px;height:32px;padding:0 10px;border:1px solid #d9d9d9;border-radius:4px;
      &:focus{border-color:#ff6f61;}
    }
  }
  .fl-group{
    .hd{
      position:relative;margin:30px 0 20px;padding-left:16px;font-size:24px;
      &:after{position:absolute;top:50%;left:0;width:6px;height:20px;margin-top:-10px;background-color:#ff6f61;content:'';}
    }
    .bd{padding:20px;background-color:#f4f4f4;border-radius:4px;}
    .row{
      margin-top:10px;
      &:first-child{margin-top:0;}
      > .fl{width:50%;}
    }
    .h3{margin-top:30px;color:#000;font-size:18px;}
  }
  .product-info{
    p{margin-right:32px;margin-bottom:24px;}
    .form-item.m-r{margin-right:16px;}
  }
  .insurance-price{
    padding-left:24px;font-size:12px;
    .item{margin-top:5px;color:#666;}
  }
  .table{
    width:100%;
    td{padding:6px 8px;}
  }
</style>
